<template>
    <div :class="['home']">
        <section v-show="!isList && !searchList">
            <div class="amap-map" id="map"></div>
            <home-sidebar v-if="show_sider" :setMarkers="setMarkerwsj"></home-sidebar>
            <div v-if="!show_sider" class="home-show flex ac jc" @click="show_sider = true"></div>
            <!-- 返回景区 -->
            <div :class="['return-scenic', bottomMargin ? 'iphonex-mb' : '']" v-if="returnViewShow"
                @click="returnCenter">
                <i class="icon"></i>
                返回景区
            </div>
            <!-- 景区详情 -->
            <detail-view ref="detailview" v-if="detailViewShow" :isLineMarker="isLineMarker" :isAuth="is_auth" :markerData="prevTargetMarker.getExtData()"></detail-view>

            <!-- 选择路线 -->
            <div class="line-select-view" v-if="lineSelectShow">
                <div class="bg" @click="lineSelectShow = false"></div>
                <div class="main">
                    <div class="bgs"></div>
                    <div v-for="(item, index) in lineData" :key="index" :class="['line-item',bottomMargin && index == lineData.length - 1? 'iphonex-pd-bottom': '',]" @click="showLineDetail(index)">
                        <div class="title">{{ index + 1 }}.{{ item.title }}</div>
                        <div class="info">
                            <span>{{ item.point_list.length }}个地点</span>
                            <span>{{ item.distance < 1000 ? item.distance + "m" : (item.distance / 1000).toFixed(2) + "km" }}</span>
                        </div>
                    </div>
                </div>
            </div>

            <!-- 线路详情 -->
            <line-view v-if="lineDetailShow" :lineData="lineData" :index="selectLineIndex"></line-view>

            <!-- 自动播放 -->
            <auto-play v-if="setAutoPlay == 'true'"></auto-play>
        </section>
        <section v-show="isList && !searchList" class="home-list">
            <section class="choice flex ac" @click="choice_type_pop = !choice_type_pop">
                推荐排序
                <div class="arrow_down"></div>
            </section>

            <div v-if="choice_type_pop" class="pop flex ae jc">
                <div class="content">
                    <div :class="['top1 flex ac jc',choice_type == 'reading' ? 'actives' : '' ]" @click.stop="getSort('reading')">按热度排序</div>
                    <div :class="['top1 flex ac jc',choice_type == 'thumb' ? 'actives' : '' ]" @click.stop="getSort('thumb')">按点赞排序</div>
                    <div class="top1 top2 flex ac jc" @click = "choice_type_pop = false">取消</div>
                </div>
            </div>

            <section class="choice-tabs">
                <section class="msg-tabs flex" v-for="item in List_msg" @click="openPoiData(item.spot_id, item.id,2)">
                    <img :src="item.images" alt="" />
                    <section class="right">
                        <div class="flex ac jb">
                            <div class="right-title only bold">{{ item.pname }}</div>
                            <div class="flex ac distent" v-if="item.dis">
                                <div class="local flex ac jc"></div>
                                {{ item.dis < 1000 ? (item.dis).toFixed(0) + "m" : (item.dis / 1000).toFixed(0) + "km" }}
                            </div>
                        </div>

                        <div class="flex ac" v-if="item.label_list">
                            <div class="name flex ac jc" v-for="itm in item.label_list">
                                {{ itm }}
                            </div>
                        </div>
                        <div class="flex ac home-icon">
                            <div class="flex ac jc mr30">
                                <div class="pinglun flex ac jc"></div>
                                {{ item.comments }}
                            </div>
                            <div class="flex ac jc">
                                <div class="dianzan flex ac jc"></div>
                                {{ item.thumb }}
                            </div>
                        </div>
                    </section>
                </section>
            </section>
        </section>
        <home-header v-if="!detailViewShow && !searchList" :mapData="mapData" :setMarkers="setMarkers" :moreModelToogle="moreModelToogle"></home-header>
        <div v-if="!searchList" class="search flex ac jc" @click="getSearch()"></div>
        <div class="person-tabs" v-if="!searchList && icon_share == 1">
            <div class="ts-tbs" @click="openPage({ url: '/pages/my/index' })">
                <div class="person flex ac jc"></div>
                <div class="span flex ac jc">个人中心</div>
            </div>
            <div class="ts-tbs" @click="openPage({ url: '/pages/buy/index' })">
                <div class="tickets flex ac jc"></div>
                <div class="span flex ac jc">购票</div>
            </div>
        </div>
        <div v-if="!searchList  && icon_share == 2" class="persons"  @click="openPage({ url: '/pages/my/index' })"></div>
        <!-- <div v-if="!searchList && icon_share == 1" class="tickets" ></div> -->
        <div v-if="!searchList" class="home-tabchange flex ac jc">
            <div @click="changeType(1)" :class="['home-tab1  flex ac jc', icon_share == 1 ? 'active' : '']">
                地图
            </div>
            <div @click="changeType(2)" :class="['home-tab1  flex ac jc', icon_share == 2 ? 'active' : '']">
                列表
            </div>
        </div>
        <!-- 定位 -->
        <location v-show="false" v-if="isLBS&&map&&mapInitEnd" :bottomMargin="bottomMargin"></location>
        <!-- 搜索 -->
        <section class="search-list" v-if="searchList">
            <div class="flex ac jb">
                <section class="searchs flex ac jb">
                    <input type="text" v-model="input_search" @keyup.13="getSearch()" placeholder="请输入搜索关键词">
                    <div class="search-icon flex ac jc" @click="getSearch()"></div>
                </section>
                <section class="cancel flex ac jc"  @click="searchList = false">取消</section>
            </div>
            <section class="search-type">
                <div :class="['tab-cell flex ac jc',currentMarkersIndex == index ? 'active' :'']" v-for="(item,index) in mapData" @click="setMarkers(item.markers,item.icon,item.defaultImg,index);seacrchChoice()">{{item.type}}
                    <div class="border-active flex ac jc"></div>
                </div>
            </section>
            <section class="boder"></section>
            <section class="flex ac jb search-address" v-for="itm in searchTab" @click="goAddress(itm.spot_id,itm.id)">
                <div class="flex ac jc left">
                    <img class="left-img" :src="itm.image_url" alt="">
                    {{itm.pname}}
                </div>
                <div class="flex ac jc left" v-if="itm.distance">{{ itm.distance < 1000 ? (itm.distance).toFixed(2) + "m" : (itm.distance / 1000).toFixed(0) + "km" }}</div>
            </section>
        </section>
    </div>
</template>

<script>
    var util = require("@/utils/util");
    var auth = require("@/utils/auth");

    import Notice from "@/components/Notice.vue";
    import Weather from "@/components/Weather.vue";
    import Location from "@/components/Location.vue";
    import DetailView from "@/components/DetailView.vue";
    import LineView from "@/components/LineView.vue";
    import AutoPlay from "@/components/AutoPlay.vue";
    import LanguageSet from "@/components/LanguageSet.vue";
    import HomeSidebar from "@/components/home-sidebar.vue";
    import HomeHeader from "@/components/home-header.vue";
    import AMap from "AMap";
    import { Range, Toast, Indicator, Popup } from "mint-ui";
    // 测试JSON数据

    export default {
        name: "home",
        components: {
            Notice,
            Weather,
            Location,
            DetailView,
            LineView,
            Indicator,
            LanguageSet,
            Range,
            HomeSidebar,
            HomeHeader,
            AutoPlay,
        },
        data() {
            return {
                map_id: 1, //地图id
                isdark: "1", //是否黑夜模式
                scenicInfo: [],
                showGuanzhu: false, // 一键关注
                sceniceModelShow: false, //景区介绍激活弹框
                bottomMargin: false, //iphoneX底部兼容性
                navMoreShow: false, //顶部查看更多数据点模态框
                isFirst: true, //是否初始化地图
                map: null, //地图对象
                mapInitEnd: false,
                mapUrl: "", //手绘地图地址
                map_slice: null, //是否切片手绘地图模式
                map_slice_url: "", //切片地图目录
                slice_root_src: "https://guchengwan.oss-cn-beijing.aliyuncs.com/", //切片库根目录地址
                is_auth: true, //地图是否激活,默认不弹窗
                mapCenter: [118.877274,31.340632],
                maxZoom: 19,
                bounds: {
                    //地图范围区域
                    SouthWest: [], //西南角（左下）
                    NorthEast: [], //东北角（右上）
                },
                scenicTell: "", //景区电话
                mapData: [], //地图数据点数据集合
                navData: [], //地图导航数据
                lineData: [], //地图线路******************
                currentMarkers: [], //当前显示的数据点组
                currentMarkersIndex: 0, //当前点击的数据点
                cluster: false, // 添加数据点插件
                returnViewShow: false, //返回景区按钮显示
                detailViewShow: false, //景点详情弹窗
                lineDetailShow: false, //线路详情窗口
                lineSelectShow: false, //线路选择窗口
                selectLineIndex: 0, //选择路线索引
                prevTargetMarker: null, //存储当前点击的marker，用于还原点击新图标时的样式控制。
                currentLinePoly: null, //存储当前线路
                isLineMarker: false, //判断点击的图标是否属于路线内
                currentLineMarkersIndex: 0, //当前点击的路线点索引值
                noticeData: [], //通知消息
                mapSetViewShow: false, //地图设置窗口
                setShowMap: true, //是否显示手绘地图
                setAutoPlay: false, //设置自动播放
                imageLayer: {}, //手绘地图对象
                isLBS: true, //是否支持定位
                userImg: "",
                selectView: 0,
                currentPosition: null, //当前位置
                currentAudio: null, //当前播放的音频
                currentAudioState: false, //当前播放音频样式控制
                currentTargetAudio: null, //当前点击的音频
                lang: "cn", //当前语言
                langConfig: [], //语言包库
                langData: {},
                reload: false,
                languageModelShow: false,
                rescueUrl: [], //第三方外链
                all_var: [], //新增vr链接
                icon_share: 1,
                isList: false, //是否是了列表模式
                List_msg: [], // 信息点列表
                searchList: false, //是否是搜索状态
                searchTab:[], //搜索列表
                input_search:'', // 输入框
                choice_type:"", //选择排序类型
                choice_type_pop:false,
                show_sider: false, // 展示侧边
                spoit_type:'', /// 类型id
            };
        },
        mounted() {
            var that = this;
            that.lang = this.$route.query.lang || "cn";
            var token = this.$route.query.t;
            auth.setToken(token);
            // 获取导航数据
            this.getVisitRoute(this.map_id);
            this.getMapNavData(this.map_id);
            // 获取地图数据
            this.getScenicMapData(this.map_id);
            // 获取路线数据
            if (that.$route.query.line) {
                setTimeout(() => {
                    that.lineSelect()
                }, 800);
            } else if (that.$route.query.id) {
                var spot_id = that.$route.query.spot_id;
                var point_id = this.$route.query.id;
                setTimeout(() => {
                    that.openPoiData(spot_id, point_id);
                }, 800);
            }
            this.getListMsg();
            this.isIphone();
            var historyLength = window.history.length;
            window.onhashchange = function (e) {
                let params = that.getHashParameters(window.location.hash);
                var isdark = params.isdark;
                if (isdark == "true") {
                    that.isdark = true;
                } else {
                    that.isdark = false;
                }
                var lang = params.lang;
                if (lang != that.lang) {
                    that.lang = lang;
                    auth.setLanguage(that.lang);
                }
                // 手绘地图
                var divMap = params.divMap;
                that.setShowMap = divMap;
                that.setMapEvent();
                // 自动播放
                that.setAutoPlay = that.$route.query.autoPlay;

            };

            // 监听页面是否返回
            window.addEventListener("popstate", function (e) {
                if (historyLength < window.history.length) {
                    historyLength = window.history.length;
                } else {
                    wx.miniProgram.navigateBack();
                }
            });
        },
        watch: {
            detailViewShow() {
                if (this.currentAudio) {
                    this.currentAudioState = !this.currentAudio.paused;
                }
            },
        },

        methods: {
            getSort(e) { //排序
                if (this.choice_type == e) {
                    return
                } else {
                    this.choice_type = e
                    this.getListMsg(this.spoit_type);
                    this.choice_type_pop = false
                }
            },
            getSearch() {//获取搜索数据
                const that = this;
                Indicator.open("加载中...");
                util.requests("post", {
                        url: "map_data/mapSearch",
                        data:{spot_id:that.mapData[this.currentMarkersIndex].id,pname:that.input_search}
                    }).then((res) => {
                        Indicator.close();
                        if (res.code) {
                            that.searchList = true;
                            that.searchTab = res.data.data;
                            if (that.searchTab.length > 0) {
                                var list = that.searchTab;
                                var timer = setInterval(() => {
                                    if (that.currentPosition) {
                                        that.searchTab = []
                                        list.forEach(item => {
                                            item.distance = '';
                                            item.distance = AMap.GeometryUtil.distance(
                                                [item.lng,item.lat],
                                                that.currentPosition
                                            );
                                        });
                                        that.searchTab = list
                                        clearTimeout(timer)
                                    }
                                }, 500);
                            }
                        }
                    });
            },
            goAddress(spot_id, point_id) { // 搜索跳转地图页面
                this.searchList = false;
                this.changeType(1);
                this.openPoiData(spot_id, point_id);
            },
            seacrchChoice() { //点击搜索类别
                this.getSearch()
            },
            // 显示更多模态框
            moreModelToogle() {
                this.navMoreShow = !this.navMoreShow;
            },
            changeType(e) {
                if (this.icon_share == e) {
                    return;
                } else {
                    if (e == 2) {
                        this.isList = true;
                        this.detailViewShow = false
                    } else {
                        this.isList = false;
                    }
                    this.icon_share = e;
                }
            },
            getListMsg(e) {
                let that = this;
                Indicator.open("加载中...");
                if (!e) {
                    e = that.mapData[that.currentMarkersIndex].id
                }
                util.requests("post", {
                        url: "map_data/listScenicData",
                        data:{order:that.choice_type,spot_id:e}
                    }).then((res) => {
                        Indicator.close();
                        if (res.code) {
                            that.List_msg = res.data.data;
                            var timer = setInterval(() => { 
                                if (that.currentPosition) {
                                    (res.data.data).forEach(item => {
                                        item.dis = '';
                                        item.dis = AMap.GeometryUtil.distance(
                                            [item.lng,item.lat],
                                            that.currentPosition
                                        );
                                    });
                                    clearTimeout(timer)
                                    that.List_msg = []
                                    that.List_msg = res.data.data;
                                }
                            }, 500);
                            // console.log(that.List_msg)
                        }
                    });
            },
            // 打开路线
            openLineData(line_id) {
                if (line_id && that.lineData.length > 0) {
                    that.sceniceModelShow = false;
                    that.lineData.forEach((item, index) => {
                        if (item.id == line_id) {
                            that.showLineDetail(index);
                        }
                    });
                }
            },
            // 打开数据点
            openPoiData(spot_id, point_id,show) {
                var that = this;
                if (spot_id && point_id && that.mapData.length > 0) {
                    that.sceniceModelShow = false;
                    that.mapData.forEach((item, index) => {
                        if (item.id == spot_id) {
                            that.setMarkers(item.markers, item.icon, item.defaultImg, index);
                            item.markers.forEach((marker, mkIndex) => {
                                if (marker.id == point_id) {
                                    that.map.setZoom(that.maxZoom);
                                    that.map.panTo(that.currentMarkers[mkIndex].position);
                                    that.markerEvent(that.currentMarkers[mkIndex], false);
                                }
                            });
                        }
                    });
                }
                if (show) { 
                    that.changeType(1)
                }
            },
            // 获取页面数据点参数跳转
            getPageMarkerData() {
                var that = this;
                // 获取白天或黑夜模式
                var isdark = that.$route.query.isdark;
                if (isdark == "true") {
                    that.isdark = true;
                } else {
                    that.isdark = false;
                }
                var lang = that.$route.query.lang;
                if (lang != that.lang) {
                    that.lang = lang;
                }
                var spot_id = that.$route.query.spot_id;
                var point_id = that.$route.query.point_id;
                that.openPoiData(spot_id, point_id);
                var line_id = that.$route.query.line_id;
                that.openLineData(line_id);
                // 手绘地图切换
                var divMap = that.$route.query.divMap;
                that.setShowMap = divMap;
                that.setMapEvent();
                // 自动播放
                that.setAutoPlay = that.$route.query.autoPlay;
            },
            isIphone() {
                var isIphone = /iphone/gi.test(window.navigator.userAgent);
                var windowW = window.screen.width;
                var windowH = window.screen.height;
                var pixelRatio = window.devicePixelRatio;
                var isIPhoneX =
                    isIphone &&
                    pixelRatio &&
                    pixelRatio === 3 &&
                    windowW === 375 &&
                    windowH === 812;
                var isIPhoneXSMax =
                    isIphone &&
                    pixelRatio &&
                    pixelRatio === 3 &&
                    windowW === 414 &&
                    windowH === 896;
                var isIPhoneXR =
                    isIphone &&
                    pixelRatio &&
                    pixelRatio === 2 &&
                    windowW === 414 &&
                    windowH === 896;
                if (isIPhoneX || isIPhoneXSMax || isIPhoneXR) {
                    // 底部增加line-main-pb样式
                    this.setDataArr({
                        bottomMargin: true,
                    });
                }
            },
            // 获取列表导航数据
            getMapNavData(map_id) {
                let that = this;
                util.requests("post", {
                        url: "map_data/getData",
                        data: { map_id: map_id },
                    }).then((res) => {
                        if (res.code == 1) {
                            that.setDataArr({
                                navData: res.data,
                            });
                        }
                    });
            },

            // 获取地图数据
            getScenicMapData(map_id) {
                Indicator.open("加载中...");
                let that = this;
                that.mapInitEnd = false;
                util
                    .requests("post", {
                        url: "map_data/getData",
                        data: { map_id: map_id },
                    })
                    .then((res) => {
                        if (res.code == 1) {
                            var data = res.data;
                            that.bounds.SouthWest = [data.top_left_lng, data.bottom_right_lat];
                            that.bounds.NorthEast = [data.bottom_right_lng, data.top_left_lat];
                            let obj = {
                                mapUrl: data.map_url,
                                is_auth: data.is_auth,
                                guideFlag: data.guide_flag,
                                play_area: data.play_area,
                                maxZoom: data.max_zoom > 0 ? data.max_zoom : 19,
                                map_slice: data.map_slice,
                                map_slice_url:
                                that.slice_root_src + data.map_slice_directory + "/"
                            };
                            if (that.sceniceModelShow && data.is_auth) {
                                obj.sceniceModelShow = false;
                            }
                            that.setDataArr(obj);
                            that.setDataArr({
                                scenicInfo: res.data.scenic_info,
                                mapCenter: [
                                    Number(data.top_left_lng),
                                    Number(data.top_left_lat)
                                ],
                                mapData: res.data.spots
                            });
                            that.setDataArr({
                                mapUrl: data.map_url,
                                is_auth: data.is_auth,
                                guideFlag: data.guide_flag,
                                play_area: data.play_area,
                                maxZoom: data.max_zoom > 0 ? data.max_zoom : 19,
                                scenicInfo: data.scenic_info,
                                // mapCenter: [118.877274,31.340632],
                                mapData: data.spots,
                                //   scenicTell: data.rescue_phone.phone,
                                langConfig: data.langConfig,
                                rescueUrl: data.rescue_phone,
                            });

                            that.init(); //初始化地图
                            if (that.mapUrl || that.map_slice === 1) {
                                this.initDivMap(); //加载手绘地图
                            }
                            this.setDefaultData(that.$route.query.spot_id); //初始化数据点
                            this.getPageMarkerData(); //加载页面参数数据点
                            // 设置当前语言包
                            // this.setLanguageMap();
                        }
                    });
                setTimeout(() => {
                    Indicator.close();
                }, 500);
            },

            // 获取资讯数据
            getScenicNotice() {
                let that = this;
                that.noticeData = [];
                util
                    .requests("post", {
                        url: "map_data/getNoticeList",
                    })
                    .then((res) => {
                        if (res.code) {
                            that.setDataArr({
                                noticeData: res.data,
                            });
                        }
                    });
            },
            openMsg() {
                this.showGuanzhu = true;
            },

            // 获取推荐路线信息
            getVisitRoute(map_id) {
                let that = this;
                Indicator.open();
                that.routeRequesting = true;
                util
                    .requests("post", {
                        url: "map_data/getScenicVisitRoute",
                        data: { map_id: map_id },
                    })
                    .then((res) => {
                        if (res.code) {
                            that.setDataArr({
                                lineData: res.data,
                            });
                        }
                        that.routeRequesting = false;
                        Indicator.close();
                    });
            },

            // 顶部导航
            topNavSelect(isPoi, p_id) {
                var that = this;
                if (isPoi === true) {
                    that.selectPoint(p_id);
                } else if (isPoi === null) {
                    that.selectView = 1;
                } else {
                    that.selectView = 2;
                }
            },
            // 选择数据点类型
            selectPoint(p_id) {
                var that = this;
                var poiData = [];
                that.selectView = 0;
                that.currentMarkersIndex = p_id;
                if (that.currentMarkers.length > 0) {
                    that.cluster.removeMarkers(that.currentMarkers);
                }
                that.currentMarkers = [];
                that.mapData.forEach((item) => {
                    if (item.id == p_id) {
                        poiData = item;
                        that.setMarkers(
                            poiData.markers,
                            poiData.icon,
                            poiData.defaultImg,
                            p_id
                        );
                        return;
                    }
                });
            },
            init() {
                // 初始化地图
                var that = this;
                let map = new AMap.Map("map", {
                    center: that.mapCenter,
                    resizeEnable: true,
                    showIndoorMap: false,
                    zoom: 12,
                    zooms: [3, that.maxZoom],
                });

                this.map = map;
                that.mapEvent();
                that.mapInitEnd = true;
                // 自定义聚合点展示
                var _renderClusterMarker = function (context) {
                    var div = document.createElement("div");
                    div.className = "amap-marker-div-clusterMarkers";
                    div.innerHTML = context.count;
                    var p = document.createElement("p");
                    p.innerText = context.markers[0].getExtData().name + "...等";
                    div.appendChild(p);
                    context.marker.setOffset(new AMap.Pixel(-15, -15)); // 坐标居中
                    context.marker.setContent(div);
                };
                that.map.plugin(["AMap.MarkerClusterer"], function () {
                    // 高德数据点聚合
                    that.cluster = new AMap.MarkerClusterer(that.map, [], {
                        averageCenter: false,
                        minClusterSize: 3, // 3个起聚合
                        renderClusterMarker: _renderClusterMarker,
                    });
                });
            },

            // 监听地图事件
            mapEvent() {
                var that = this;
                var map = that.map;
                var TL, TR, BL, BR; //地图四角经纬度，用于判断是景区是否处于当前视口范围内
                TR = that.bounds.NorthEast;
                TL = [that.bounds.NorthEast[0], that.bounds.SouthWest[1]];
                BR = [that.bounds.SouthWest[0], that.bounds.NorthEast[1]];
                BL = that.bounds.SouthWest;

                // 地图移动事件
                map.on("moveend", function () {
                    var vTL, vTR, vBL, vBR;
                    var vBounds = map.getBounds();
                    vTR = [vBounds.northeast.lng, vBounds.northeast.lat];
                    vTL = [vBounds.northeast.lng, vBounds.southwest.lat];
                    vBR = [vBounds.southwest.lng, vBounds.northeast.lat];
                    vBL = [vBounds.southwest.lng, vBounds.southwest.lat];

                    var isRingInRing1 = AMap.GeometryUtil.isRingInRing(
                        [TL, TR, BR, BL],
                        [vTL, vTR, vBR, vBL]
                    );

                    var isRingInRing2 = AMap.GeometryUtil.isRingInRing(
                        [vTL, vTR, vBR, vBL],
                        [TL, TR, BR, BL]
                    );

                    var doesRingRingIntersect = AMap.GeometryUtil.doesRingRingIntersect(
                        [TL, TR, BR, BL],
                        [vTL, vTR, vBR, vBL]
                    );
                    if (isRingInRing1 || isRingInRing2 || doesRingRingIntersect) {
                        that.returnViewShow = false;
                    } else {
                        that.returnViewShow = true;
                    }
                    // 地图点击事件
                    map.on("click", function () {
                        that.selectView = 0;
                        if (that.prevTargetMarker && !that.isLineMarker) {
                            that.markerStyleReturn();
                            that.detailViewShow = false;
                        }
                    });
                });
            },

            // 数据点样式还原
            markerStyleReturn() {
                var that = this;
                that.prevTargetMarker.setContent(that.prevTargetMarker.getExtData().icon);
                if (that.lineDetailShow) {
                    that.prevTargetMarker.setOffset(new AMap.Pixel(-13, -13));
                    that.prevTargetMarker.setLabel({ content: null, direction: "bottom" });
                } else {
                    that.prevTargetMarker.setOffset(new AMap.Pixel(-15, -30));
                    that.prevTargetMarker.setLabel({ direction: "bottom" });
                }
                that.prevTargetMarker.setzIndex(110);
                that.prevTargetMarker = null;
            },

            // 加载手绘地图
            initDivMap() {
                var that = this;
                var imageLayer;
                if (that.map_slice == 1) {
                    imageLayer = new AMap.TileLayer({
                        zIndex: 100,
                        getTileUrl: function (x, y, z) {
                            return (
                                that.map_slice_url + "tiles/" + z + "/" + x + "_" + y + ".png"
                            );
                        },
                    });
                } else {
                    imageLayer = new AMap.ImageLayer({
                        url: that.mapUrl,
                        bounds: new AMap.Bounds(that.bounds.SouthWest, that.bounds.NorthEast),
                        zIndex: 100,
                    });
                }
                that.imageLayer = imageLayer;
                this.map.add(imageLayer);
                that.setShowMap = true;
                that.map.setZoom(18);
                //   setTimeout(() => {
                //     that.map.setCenter(118.866525,31.317371);
                //   }, 500);
            },

            // 设置地图初始化数据
            setDefaultData(e) {
                if (e) {
                    this.mapData.forEach((item, index) => {
                        if (item.id == e) {
                            var defaultData = this.mapData[index];
                            this.setMarkers(
                                defaultData.markers,
                                defaultData.icon,
                                defaultData.icon_tab_selected,
                                defaultData.p_id
                            );
                            this.currentMarkersIndex = index;
                        }
                    });
                } else {
                    var defaultData = this.mapData[0];
                    this.setMarkers(
                        defaultData.markers,
                        defaultData.icon,
                        defaultData.icon_tab_selected,
                        defaultData.p_id
                    );
                }
            },

            //卫生间位置点
            setMarkerwsj() {
                this.mapData.forEach((item, index) => {
                    if (item.id == 6) {
                        this.setMarkers(item.markers,item.icon,item.defaultImg,index)
                    }
                });
            },
            // 设置数据点
            setMarkers(markersArr, icon, defaultImg, p_id) {
                var that = this;
                that.currentMarkersIndex = p_id || 0;
                that.spoit_type = p_id;
                that.getListMsg(that.mapData[that.currentMarkersIndex].id)
                if (that.currentMarkers.length > 0) {
                    that.cluster.removeMarkers(that.currentMarkers);
                }
                that.currentMarkers = [];
                if (!p_id) {
                    var type  = that.$route.query.spot_id
                } else {
                    var type = that.mapData[that.currentMarkersIndex].id;
                }
                markersArr.forEach((item, pIndex) => {
                    if (!item.position[0] || !item.position[1]) {
                        return true;
                    }
                    var marker = new AMap.Marker({
                        position: item.position,
                        content: that.divIcon(icon),
                        offset: new AMap.Pixel(-15, -30),
                        zIndex: 110,
                        clickable: true,
                        topWhenClick: true,
                        extData: {
                            id: item.id,
                            name: item.name,
                            position: item.position,
                            open:item.open,
                            tel:item.tel,
                            icon: that.divIcon(icon),
                            audio_duration: item.audio_duration,
                            audioSrc: item.audioSrc,
                            type:type,
                            info: item.info,
                            like: item.like,
                            isLike: item.has_thumbs,
                            autoPlayAear: item.autoPlayAear,
                            img: item.img ? item.img : defaultImg,
                            is_charge: item.is_charge,
                            arrIndex: pIndex,
                            is_give:item.is_give,
                            thumb:item.thumb,
                            comments:item.comments,
                            label:item.label,
                            stars:item.stars,
                            book_status:item.book_status,
                            book_url:item.book_url,
                        },
                        label: {
                            content: item.name,
                            direction: "bottom",
                        },
                    });
                    marker.on("click", function () {
                        if (that.$route.query.t == 0) {
                            wx.miniProgram.navigateBack();
                            return;
                        }
                        that.markerEvent(marker, false);
                    });
                    that.currentMarkers.push(marker);
                });
                var timer = setInterval(function () {
                    if (that.cluster) {
                        that.cluster.addMarkers(that.currentMarkers);
                        clearInterval(timer);
                    }
                }, 200);
                this.navMoreShow = false;
                that.map.setBounds(
                    new AMap.Bounds(that.bounds.SouthWest, that.bounds.NorthEast)
                );
                that.map.zoomIn();
            },

            // 点击数据点图标事件
            markerEvent(e, isLineMarker) {
                var that = this;
                that.detailViewShow = false;
                that.isLineMarker = isLineMarker;
                if (that.prevTargetMarker) {
                    that.markerStyleReturn();
                }
                var targetMarker = e;
                that.prevTargetMarker = e;
                targetMarker.setzIndex(120);
                targetMarker.setContent(`<div class="amap-current-target-marker">
      <img src="${targetMarker.getExtData().img}">
    </div>`);
                targetMarker.setOffset(new AMap.Pixel(-30, -70));
                if (that.isLineMarker) {
                    targetMarker.setLabel({
                        content: targetMarker.getExtData().name,
                        direction: "top",
                    });
                    that.currentLineMarkersIndex = targetMarker.getExtData().arrIndex;
                } else {
                    targetMarker.setLabel({ direction: "top" });
                }
                that.map.setZoom(19);
                that.map.panTo(e.getPosition());
                //   that.collection(targetMarker.getExtData().id);
                // 获取marker数据
                setTimeout(() => {
                    that.detailViewShow = true;
                    if (isLineMarker) {
                        that.navScroll();
                    }
                }, 100);
            },

            //增加用户浏览次数
            collection(point_id) {
                util
                    .requests("post", {
                        url: "Like/collection",
                        data: { type: "browse", point_id: point_id },
                    })
                    .then((res) => { });
            },

            // 锚点滚动
            navScroll() {
                setTimeout(() => {
                    var scrollBox = document.querySelector(".line-marker-top-nav");
                    var active = document.querySelector(".line-top-nav-item.active");
                    var star_scroll = scrollBox.scrollLeft;
                    var end_scroll = active.offsetLeft - scrollBox.clientWidth / 2;
                    var step = 0;
                    if (end_scroll >= star_scroll) {
                        step = (end_scroll - star_scroll) / 10;
                    } else {
                        step = -(star_scroll - end_scroll) / 10;
                    }
                    var time = 0;
                    var animation;
                    slide();
                    function slide() {
                        animation = requestAnimationFrame(slide);
                        time += 1;
                        scrollBox.scrollLeft += step;
                        if (time >= 10) {
                            cancelAnimationFrame(animation);
                        }
                    }
                }, 100);
            },

            // 自定义ICON
            divIcon(iconUrl) {
                var divIcon = `<div class="amap-marker-div-marker">
                    <img src="${iconUrl}"></div>`;
                return divIcon;
            },

            // 返回景区
            returnCenter() {
                var that = this;
                that.map.setCenter(that.mapCenter);
                that.map.setFitView(that.currentMarkers, false);
            },

            toNotice(id) {
                let that = this;
                util.openPage({
                    url: "/pages/notice/index?id=" + id + "&lang=" + that.lang,
                });
            },

            openPage: util.openPage,

            // 清除所有覆盖物数据
            clearAllLayer() {
                var that = this;
                // 清除旧路线以及旧数据点
                if (that.currentLinePoly) {
                    that.map.remove(that.currentLinePoly);
                }
                if (that.currentMarkers.length > 0) {
                    that.map.remove(that.currentMarkers);
                    if (that.cluster) {
                        that.cluster.removeMarkers(that.currentMarkers);
                    }
                    that.currentMarkers = [];
                }
            },

            // 选择线路
            lineSelect() {
                var that = this;
                if (that.routeRequesting) {
                    let limit_time = 6;
        
                    let t = setInterval(function () {
                        if (that.routeRequesting && limit_time > 0) {
                            --limit_time;
                        } else {
                            clearInterval(t);
                        }
                    }, 500);
                    if (limit_time <= 0) {
                        Toast.fail("获取推荐路线错误，请稍后再试");
                        return false;
                    }
                }
                if (that.$route.query.line) {
                    that.lineSelectShow = true;
                } else if (that.lineData.length <= 0) {
                    Toast({
                        message: "暂无推荐路线",
                    });
                } else {
                    that.lineSelectShow = true;
                }
            },

            // 显示路线
            showLineDetail(index) {
                var that = this;
                that.lineSelectShow = false;
                that.selectLineIndex = index;
                that.lineDetailShow = true;
                // 清除所有覆盖物数据
                that.clearAllLayer();

                // 画路线
                that.currentLinePoly = new AMap.Polyline({
                    path: that.lineData[index].path,
                    isOutline: true,
                    borderWeight: 1,
                    outlineColor: "#fff",
                    strokeWeight: 4, // 线条宽度，默认为 1
                    strokeColor: "#42A4F4", // 线条颜色
                    showDir: true,
                    lineJoin: "round", // 折线拐点连接处样式
                });
                // 将折线添加至地图实例
                that.map.add(that.currentLinePoly);

                // 添加地图数据点
                that.lineData[index].point_list.forEach((item, index) => {
                    var marker = new AMap.Marker({
                        position: [item.lng,item.lat],
                        content: `<div class="amap-line-marker-icon">${index + 1}</div>`,
                        offset: new AMap.Pixel(-13, -13),
                        zIndex: 110,
                        clickable: true,
                        extData: {
                            id: item.id,
                            name: item.pname,
                            position: [item.lng,item.lat],
                            icon: `<div class="amap-line-marker-icon">${index + 1}</div>`,
                            info: item.info,
                            audioSrc: item.voice_url,
                            audio_duration: item.audio_duration,
                            like: item.thumb,
                            isLike: item.has_thumb,
                            autoPlayAear: item.autoPlayAear,
                            img: item.image_url ? item.image_url : defaultImg,
                            is_charge: item.is_charge,
                            arrIndex: index,
                            is_give:item.has_thumb,
                            thumb:item.thumb,
                            comments:item.comments
                        },
                        
                    });

                    marker.getNewPayData = function () {
                        // if (marker.getExtData().audio || item.audio) {
                        that.markerEvent(marker, true);
                        // } else {
                        //   that.getPointInfo(item.id, true, marker, true, item, null, index);
                        // }
                    };
                    marker.on("click", function () {
                        // if (marker.getExtData().audio || item.audio) {
                        that.markerEvent(marker, true);
                        // } else {
                        //   that.getPointInfo(item.id, true, marker, true, item, null, index);
                        // }
                    });
                    that.currentMarkers.push(marker);
                });

                that.map.add(that.currentMarkers);
                that.map.setFitView(that.currentMarkers);
            },

            autoPlayToogle() {
                var that = this;
                that.$refs.detailview.audioPlayToogle();
            },
            getHashParameters() {
                var params = {};
                let _hash_params_idx = (location.hash || "").indexOf("?");
                if (_hash_params_idx != -1) {
                    var arr = location.hash.substring(_hash_params_idx + 1).split("&");
                    for (var i = 0; i < arr.length; i++) {
                        var data = arr[i].split("=");
                        if (data.length == 2) {
                            params[data[0]] = data[1];
                        }
                    }
                }
                return params;
            },

            audioToggle() {
                var that = this;
                if (that.currentAudio.paused) {
                    that.currentAudio.play();
                    that.currentAudioState = true;
                } else {
                    that.currentAudio.pause();
                    that.currentAudioState = false;
                }
            },

            setLanguageMap() {
                var that = this;
                console.log(that.langConfig.en["导览讲解"]);
                switch (that.lang) {
                    case "en":
                        that.langData = that.langConfig.en;
                        break;
                    case "kr":
                        that.langData = that.langConfig.kr;
                        break;
                    case "jp":
                        that.langData = that.langConfig.jp;
                        break;
                    default:
                        that.langData = that.langConfig.cn;
                        break;
                }
            },
            setMapEvent() {
                var that = this;
                if (that.setShowMap == "true") {
                    // that.imageLayer.show();
                } else {
                    // that.imageLayer.hide();
                }
            },
        },
    };
</script>