<template>
    <div class="home-header">
        <div :class="['top-nav',isIphoneX ? 'active' : '']">
            <div class="select-marker flex ac">
                <span :class="['marker-item']" v-for="(item,index) in mapData">
                    <section  @click="setMarkers(item.markers,item.icon,item.defaultImg,index)" :class="['w120',index==$parent.currentMarkersIndex?'active':'']">
                        <img class="flex ac jc" v-if="index==$parent.currentMarkersIndex" :src="item.icon" >
                        <img class="flex ac jc"  v-else :src="item.defaultImg" >
                        <div class="borders flex ac jc"></div>
                        <span :class="['flex ac jc',index==$parent.currentMarkersIndex ? 'spans' : '']">{{item.type}}</span>
                    </section>
                </span>
            </div>
            <div @click="moreModelToogle" class="more-right">
                <div class="more-btn"></div>
                <div class="more-qita flex ac jc">其他</div>
            </div>
        </div>
        <div class="top-nav-more" v-if="$parent.navMoreShow">
            <div class="bg" @click="moreModelToogle"></div>
            <div :class="['main']">
            <div class="title flex ac jb">
                <div class="flex ac jc">全部</div>
                <span class="nav-more-close" @click="moreModelToogle"></span>
            </div>
            <div class="markers-box">
                <span :class="['marker-item',index==$parent.currentMarkersIndex?'active':'']" v-for="(item,index) in mapData">
                    <section  @click="setMarkers(item.markers,item.icon,item.defaultImg,index)" :class="['all-icon',index==$parent.currentMarkersIndex?'active':'']">
                        <img v-if="index==$parent.currentMarkersIndex" :src="item.icon" >
                        <img v-else :src="item.defaultImg" >
                        <span :class="['flex ac jc',index==$parent.currentMarkersIndex ? 'spans' : '']">{{item.type}}</span>
                    </section>
                </span>
            </div>
            </div>
        </div>
    </div>
</template>

<script>
import Swiper from "swiper";
import "swiper/css/swiper.css";

export default {
    name: "Home-Header",
    props: {
        mapData: Array,
        setMarkers: Function,
        moreModelToogle:Function,
    },
    data() {
        return {
            isIphoneX: false
        };
    },
    mounted() {
        var u = navigator.userAgent;
        var isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
        if (isIOS) {
            this.isIphoneX = true
        }
    },
    methods: {
        closeNotice() {
            this.swiperShow = false;
            },
        },
    };
</script>
