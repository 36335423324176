<template>
  <div>
    <div v-show="!parent.isLineMarker" class="line-detail-view" :style="'bottom:'+ slideBottom + 'px;height:' + mainHeight + 'px;'">
        <div class="exit-line" @click="exitLine">退出路线</div>
        <div class="main" id="slideView" 
        @touchstart.capture="slideStart"
        @touchmove.capture="slideMove" 
        @touchend.capture="slideEnd"
        @mousedown.capture="slideStart"
        @mousemove.capture="slideMove" 
        @mouseup.capture="slideEnd">
            <div class="bgs"></div>
            <div class="line-select-swiper">
                <div class="swiper-wrapper">
                    <div v-for="(item,index) in lineData" class="swiper-slide">
                        <div class="line-detail-item">
                            <div class="title">{{item.title}}</div>
                            <div class="info">
                                <span>{{item.point_list.length}}个地点</span>
                                <span>{{item.distance<1000?item.distance + 'm':(item.distance/1000).toFixed(2)+'Km'}}</span>
                            </div>
                            <div :class="['like-btn',item.isLike?'active':'']" @click="likeEvent(index)">{{item.like}}</div>
                        </div>
                        <div class="line-markers-info stop-swiping">
                            <div class="line-marker-item" v-for="(marker,index) in item.point_list" @click="markerEvent(index)">
                                <div class="index">{{index==0?'起':(index==item.point_list.length-1?'终':index+1)}}</div>
                                <p class="title">{{marker.pname}}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="swiper-pagination"></div>
            </div>
        </div>
    </div>

    <div class="line-marker-top-nav" v-if="parent.isLineMarker">
      <div :class="['line-top-nav-item',parent.currentLineMarkersIndex==index?'active':'']" v-for="(item,index) in currentLineMarkers" @click="markerEvent(index)">
        <div class="index">
          {{index==0?"起":(index==currentLineMarkers.length-1?"终":index+1)}}
          <span :class="['title', index == 0 ? 'titles':'']">{{item.pname}}</span>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
var util = require("@/utils/util");
import Swiper from "swiper";
import "swiper/css/swiper.css";

export default {
  name: "LineView",
  props: {
    lineData: {
      type: Array,
      default: null
    },
    index: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      parent: this.$parent,
      currentLineMarkers:[],
      slideBottom: 0,
      currentBottom: 0,
      mainHeight: 0,
      viewHeight: 0,
      slideShow: false,
      slideStated: false,
      isAanimation: false,
      slideStartY: 0,
      slideMovedY: 0,
      slideEndY: 0,
      slideStartTime: 0,
      slideEndTime: 0,
      speed: 0
    };
  },
  mounted() {
    var that = this;
    that.currentLineMarkers = that.lineData[that.index].point_list;
    var mySwiper = new Swiper(".line-select-swiper", {
      initialSlide: that.index,
      noSwiping: true,
      noSwipingClass: "stop-swiping",
      pagination: {
        el: ".swiper-pagination"
      },
      on: {
        slideChangeTransitionEnd: function() {
          that.$parent.showLineDetail(this.activeIndex);
          that.currentLineMarkers = that.lineData[this.activeIndex].point_list;
        }
      }
    });
    var mainHeight = document.querySelector("#slideView");
    that.mainHeight = mainHeight.clientHeight + 10;
    var slideBottom = document.querySelector("#slideView .line-markers-info");
    that.slideBottom = -slideBottom.clientHeight + 10;
  },
  methods: {
    exitLine() {
      this.$parent.lineDetailShow = false;
      this.$parent.clearAllLayer();
      this.$parent.setDefaultData();
    },
    
    likeEvent(idx) {
      let that = this;
      let currentRoute = that.lineData[idx];
      if (currentRoute.isLike){
        that.lineData[idx].like--;
      }else{
        that.lineData[idx].like++
      }
      if (that.lineData[idx].isLike) {
        var like = 0
      } else {
        var like = 1
      }
      that.lineData[idx].isLike = !currentRoute.isLike
      util.requests('post', {
        url: 'map_data/visitRouteThumbs',
        data: {route_id: currentRoute.id,operation:like}
      }).then(res=>{
      })
    },

    markerEvent(index) {
      var that = this;
      that.$parent.currentLineMarkersIndex = index;
      that.$parent.currentMarkers[index].getNewPayData();
    //   that.$parent.markerEvent(, true);
      that.$parent.markerEvent(that.$parent.currentMarkers[index], true);
    },

    slideStart(e) {
      var that = this;
      that.slideStated = true;
      that.isAanimation = true;
      var slideStartY = e.clientY || e.changedTouches[0].clientY;
      that.slideStartY = slideStartY;
      that.slideMovedY = 0;
      that.currentBottom = that.slideBottom;
      that.slideStartTime = new Date().getTime();
    },

    slideMove(e) {
      var that = this;
      var minBottom = document.querySelector("#slideView .line-markers-info")
        .clientHeight;
      if (that.slideStated) {
        var slideMoveY = e.clientY || e.changedTouches[0].clientY;
        that.slideMovedY = that.slideStartY - slideMoveY;
        that.slideBottom = that.currentBottom + that.slideMovedY;
        if (that.slideBottom > 10) {
          that.slideBottom = 10;
        } else if (that.slideBottom < -minBottom + 40) {
          that.slideBottom = -minBottom + 40;
        }
      }
    },

    slideEnd(e) {
      var that = this;
      that.slideStated = false;
      var slideNext = that.slideMovedY;
      that.slideEndTime = new Date().getTime();
      var timeSub = that.slideEndTime - that.slideStartTime;
      if (timeSub > 0 && slideNext != 0) {
        var minBottom = document.querySelector("#slideView .line-markers-info")
          .clientHeight;
        that.speed = Math.abs(Math.abs(slideNext) / timeSub) * 16;
        var f = 0;
        var timer = true;
        interval();
        function interval() {
          timer && requestAnimationFrame(interval);
          f = Math.min(Math.abs(that.speed) / 32, 0.5);
          that.speed -= f;
          if (slideNext >= 0) {
            that.slideBottom += that.speed;
          } else {
            that.slideBottom -= that.speed;
          }
          if (that.speed <= 0.2 || that.slideStated) {
            timer = false;
            that.speed = 0;
            that.isAanimation = false;
          }
          if (that.slideBottom > -13) {
            that.slideBottom = -8;
          } else if (that.slideBottom < -minBottom + 40) {
            that.slideBottom = -minBottom -8;
          }
        }
      } else {
        that.isAanimation = false;
      }
    }
  }
};
</script>

