<template>
    <div class="puop" v-if="isloading">
        <div class="bg" @click="Not" ></div>
        <div class="pop-content" v-if="isloading">
            <div v-for="(item,index) in langList" :class="['flex ac jb tabx',choice_langue == item.lang?'active':'']" @click="changeLangue(item.lang)" :key="index">
                <span>{{item.dec}}</span>
                <div :class="['pop-choice',choice_langue == item.lang ? 'active' : '']"></div> 
            </div>
            <div class="buttonx flex ac jc" @click="Sure">确定</div>
        </div>
    </div>
</template>

<script>
import "swiper/css/swiper.css";
import { Toast, Popup, Indicator } from "mint-ui";
var util = require("@/utils/util");

export default {
  name: "Notice",
  props: {},
  components: {
    Toast,
    Indicator
  },
  data() {
    return {
      langList: [],
      isloading: false,
      choice_langue: this.$parent.lang
    };
  },
  mounted() {
    this.getLan();
  },
  methods: {
    getLan() {
      let that = this;
      Indicator.open();
      util
        .requests("post", {
          url: "Language/languageTypeList"
        })
        .then(res => {
          console.log(res);
          that.langList = res.data;
          Indicator.close();
          that.isloading = true;
        });
    },
    Sure() {
      let that = this;
      util
        .requests("post", {
          url: "Language/languageTypeList",
          data: { lang: that.choice_langue }
        })
        .then(res => {
          that.ReplaceParamVal('lang',that.choice_langue);
          that.$parent.lang = that.choice_langue;
          that.$parent.languageModelShow = false;
        });
    },
    changeLangue(lan) {
      if (this.choice_langue == lan) {
      } else {
        this.choice_langue = lan;
      }
    },
    Not() {
      console.log(1);
      this.$parent.languageModelShow = false;
    },
    //替换指定传入参数的值,paramName为参数,replaceWith为新值
    ReplaceParamVal(paramName, replaceWith) {
      var oUrl = this.$route.fullPath;
      var re = eval("/(" + paramName + "=)([^&]*)/gi");
      var nUrl = oUrl.replace(re, paramName + "=" + replaceWith);
      this.$router.push(nUrl)
    }
  }
};
</script>





