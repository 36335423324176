<template>
    <div>
        <div :class="['location-view']"  @click="getGeolocation">
            <div :class="['location-icon',isLoading?'loading':'']"></div>
        </div>
        <div v-if="isshowPop" class="flex ac jc location-view-pop">
            <div class="contain">
                <h3 class="flex ac jc">为了更好的用户体验,请先允许方向授权</h3>
                <div class="flex ac jc btn">
                    <button class="flex ac jc c9 br1" @click="isshowPop ='false'">取消</button>
                    <button class="flex ac jc" @click="changeSure">确定</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
var util = require("@/utils/util");
import AMap from "AMap";
import { Toast } from "mint-ui";
export default {
  name: "Location",
  props: ["bottomMargin"],
  data() {
    return {
      map: this.$parent.map,
      userImg: "",
      currentMarker: null,
      currentPosition: [],
      geolocation: null,
      isType:util.phoneType(),
      isLoading: false,
      locationLoding: false,
      isFail: false,
      isSuccess: false, //是否定位获取成功
      isshowPop:false
    };
  },
  mounted() {
    let that = this;
    that.setLocation();
    that.getUserImg();
    // 陀螺仪
    var ua = navigator.userAgent.toLowerCase();
    window.onhashchange = function(e) {
        let params = that.$parent.getHashParameters(window.location.hash);
        if(params.lng&&params.lat){
            that.currentPosition = [params.lng, params.lat];
            that.$parent.currentPosition = [params.lng, params.lat];
            that.setCurrentMarker([params.lng, params.lat]);
        }
            if(that.currentMarker){
                document.querySelector(".amap-geolocation-bg").style.transform = `rotate(${Math.round(360 - params.rotate)}deg)`;
        }
    }

    if (window.DeviceOrientationEvent) {
      if (/android/.test(ua)) {
        window.addEventListener(
          "deviceorientationabsolute",
          DeviceOrientationHandler,
          false
        );
        function DeviceOrientationHandler(event) {
          if (that.currentMarker) {
            document.querySelector(
              ".amap-geolocation-bg"
            ).style.transform = `rotate(${Math.round(360 - event.alpha)}deg)`;
          }
        }
      } else if (/iphone/.test(ua)) {
          setTimeout(() => {
            that.requestPermissionsIOS();
          }, 5000);
      } else {
        window.addEventListener(
          "deviceorientation",
          function(event) {
            if (that.currentMarker) {
              document.querySelector(
                ".amap-geolocation-bg"
              ).style.transform = `rotate(${event.webkitCompassHeading}deg)`;
            }
          },
          false
        );
      }
    }
  },
  methods: {
    changeSure() {
        var that = this;
        localStorage.setItem('tuoluoyi',true)
        that.isshowPop = false;
        setTimeout(() => {
            that.requestPermissionsIOS();
        }, 3000);
    },
    // 定位功能
    setLocation() {
      var that = this;
      var geolocation = new qq.maps.Geolocation();
      that.geolocation = geolocation;
      that.isLoading = true;
      geolocation.getLocation(that.onComplete, that.onError);
      geolocation.watchPosition(that.onComplete);
    },
    //解析定位结果
    onComplete(data) {
      var that = this;
      that.currentPosition = [data.lng, data.lat];
      that.$parent.currentPosition = [data.lng, data.lat];
      that.setCurrentMarker([data.lng, data.lat]);
      that.isLoading = false;
      if (that.locationLoding) {
        that.map.panTo(that.currentPosition);
        that.map.setZoom(17);
        that.locationLoding = false;
      }
        if(that.isType == 'IOS') {
            if(!localStorage.getItem('tuoluoyi')) {
                setTimeout(() => {
                    that.isshowPop = true
                }, 100);
            }
        } else if (that.isType == 'weixin'){
            var ua = window.navigator.userAgent;
            if (ua.indexOf("iPhone") > -1 || ua.indexOf("iOS") > -1) {
                if(!localStorage.getItem('tuoluoyi')) {
                    that.isshowPop = true
                }
            }
        }
      that.isSuccess = true;
    },
    //解析定位错误信息
    onError(data) {
      // console.log(data);
      if (!this.isFail) {
        Toast({
          message: "定位失败，请打开GPS定位或到空旷的地方再尝试",
          duration: 3000
        });
        this.isLoading = false;
        this.locationLoding = false;
        this.isFail = true;
      }
    },
    // 获取定位事件
    getGeolocation() {
      var that = this;
      that.isLoading = true;
      that.locationLoding = true;
      that.isFail = false;
      that.geolocation.getLocation(that.onComplete, that.onError);
    },
    // 设置当前定位的marker
    setCurrentMarker(position) {
      var that = this;
      if (that.currentMarker) {
        that.currentMarker.setPosition(position);
      } else if(that.userImg){
        that.currentMarker = new AMap.Marker({
          map: that.map,
          position: position, // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
          offset: new AMap.Pixel(-32, -32),
          zIndex: 105,
          content: `<div class="amap-geolocation-box">
          <img class="user-head" src="${that.userImg}">
          <div class="amap-geolocation-bg"></div>
          </div>`
        });
      }
    },
    getUserImg(){
      var that = this;
      util.requests("post", {
        url: "map_data/getUserAvatar"
      }).then(res => {
        console.log(res);
        if (res.code == 1) {
          that.userImg = res.data;
        }
      });
    },
    Success() {
      // 陀螺仪
      var that = this;
      var ua = navigator.userAgent.toLowerCase();
      if (window.DeviceOrientationEvent) {
        if (/android/.test(ua)) {
          window.addEventListener("deviceorientationabsolute",DeviceOrientationHandler,false);
          function DeviceOrientationHandler(event) {
            if (that.currentMarker) {
              document.querySelector(".amap-geolocation-bg").style.transform = `rotate(${Math.round(360 - event.alpha)}deg)`;
            }
          }
        } else if (/iphone/.test(ua)) {
          console.log("苹果手机");
          that.requestPermissionsIOS();
        } else {
          window.addEventListener("deviceorientation",function(event) {
              if (that.currentMarker) {
                document.querySelector(".amap-geolocation-bg").style.transform = `rotate(${event.webkitCompassHeading}deg)`;
              }
            },
            false
          );
        }
      }
    },
    requestPermissionsIOS() {
      this.requestDeviceMotionIOS();
      this.requestDeviceOrientationIOS();
    //   var times = setInterval(() => { // 暂时去掉ios 的指针
    //     if(document.querySelector(".amap-geolocation-bg")) {
    //         document.querySelector(".amap-geolocation-bg").style.display = `none`;
    //         clearInterval(times)
    //     }
    //   },100);
    },
    requestDeviceMotionIOS() {
      var that = this;
      if (typeof DeviceMotionEvent.requestPermission === "function") {
        DeviceMotionEvent.requestPermission()
          .then(permissionState => {
            console.log("进行授权判断");
            console.log(permissionState);
            if (permissionState === "granted") {
              console.log("授权成功");
              window.addEventListener("devicemotion", () => {
                if (that.currentMarker) {
                  document.querySelector(
                    ".amap-geolocation-bg"
                  ).style.transform = `rotate(${
                    event.webkitCompassHeading
                  }deg)`;
                }
              });
            }
          })
          .catch(err => {
            console.log(err);
            console.log("用户未允许权限");
          });
      } else {
        // handle regular non iOS 13+ devices
      }
    },
    requestDeviceOrientationIOS() {
      var that = this;
      if (typeof DeviceOrientationEvent.requestPermission === "function") {
        DeviceOrientationEvent.requestPermission()
          .then(permissionState => {
            console.log("进行授权判断");
            console.log(permissionState);
            if (permissionState === "granted") {
              console.log("授权成功");
              window.addEventListener("deviceorientation", () => {
                if (that.currentMarker) {
                  document.querySelector(
                    ".amap-geolocation-bg"
                  ).style.transform = `rotate(${
                    event.webkitCompassHeading
                  }deg)`;
                }
              });
            }
          })
          .catch(err => {
            console.log(err);
            console.log("用户未允许权限");
          });
      } else {
        // handle regular non iOS 13+ devices
      }
    }
  }
};
</script>

