<template>
    <div class="home-sidder">
        <div class="tab-icon" @click="openPage({url: '/pages/find/index'})">
            <div class="flex ac jc imgs find"></div>
            <div class="dec flex ac jc">发现</div>
        </div>
        <div class="tab-icon"  @click="openPage({url: '/pages/detail/index'})">
            <div class="flex ac jc imgs jieshao"></div>
            <div class="dec flex ac jc">景区介绍</div>
        </div>
        <div class="tab-icon" @click="$parent.lineSelect()">
            <div class="flex ac jc imgs line"></div>
            <div class="dec flex ac jc">游览线路</div>
        </div>
        <!-- <div class="tab-icon"  @click="openPage({url: '/pages/find/index?type=play'})">
            <div class="flex ac jc imgs shijian"></div>
            <div class="dec flex ac jc">演出时间</div>
        </div>
        <div class="tab-icon" @click="openPage({url: '/pages/empty/index'})">
            <div class="flex ac jc imgs waimai"></div>
            <div class="dec flex ac jc">园内外卖</div>
        </div> -->
        <div class="tab-icon">
            <div class="flex ac jc imgs weisheng"  @click="setMarkers()"></div>
            <div class="dec flex ac jc">卫生间</div>
        </div>
        <!-- <div class="tab-icon" @click="openPage({url: '/pages/empty/index'})">
            <div class="flex ac jc imgs jiaofei"></div>
            <div class="dec flex ac jc">停车缴费</div>
        </div> -->
        <div class="tab-icon"  @click="openPage({url: '/pages/empty/other/index?type=1'})">
            <div class="flex ac jc imgs shihua"></div>
            <div class="dec flex ac jc">AI识花</div>
        </div>
        <!-- <div class="tab-icon" @click="openPage({url: '/pages/empty/other/index?type=2'})">
            <div class="flex ac jc imgs shangcheng"></div>
            <div class="dec flex ac jc">电子商城</div>
        </div> -->
        <div class="backs flex ac jc" @click="$parent.show_sider = false">
            <div class="back"></div>
        </div>
    </div>
</template>
<script>
var util = require("@/utils/util");
import { Range, Toast, Indicator } from "mint-ui";
export default {
    name:'Home-Sidebar',
    props: {
        initDivMap: Function,
        setMarkers: Function,
    },
    data() {
        return {
        }
    },
    mounted(){
    },
    methods: {
        openPage: util.openPage,
    }
}
</script>