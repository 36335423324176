<template>
<div :class="['weather-view',showDetail?'detail':'']" v-if="hasData">
  <div class="weather-info">
  <!-- <div class="weather-info" @click="showWeatherModel"> -->
    {{temperature}}℃
    <img class="img-icon" :src="imgSrc">
  </div>
  <!-- <div class="weather-detail" v-if="showDetail">
      <div class="bg" @click="showDetail=false"></div>
      <div class="mt-main" id="js-detail">
      </div>
  </div> -->
</div>
    
</template>

<script>
var util = require("@/utils/util");
export default {
  name: "weather-view",
  data() {
    return {
      temperature: 0,
      imgSrc: "",
      hasData: true,
      hefenKey: "256e4ffd7bc241bd95ef1a8b09108987",
      cid: 0,
      language:'zh',
      showDetail: false
    };
  },
  mounted() {
    var position = this.$parent.mapCenter[0] + "," + this.$parent.mapCenter[1];
    this.getCityId(position);
  },
  methods: {
    detailClose() {
      this.showDetail = false;
    },
    showWeatherModel() {
      var that = this;
      that.showDetail = true;
      setTimeout(() => {
        var box = document.querySelector("#js-detail");
        if (box.innerHTML == "") {
          var js1 = document.createElement("script");
          js1.innerHTML = `WIDGET = {
          CONFIG: {
            "layout": 2,
            "width": "230",
            "height": "270",
            "background": ${that.$parent.isdark?'3':'2'},
            "language": "${that.language}",
            "dataColor": ${that.$parent.isdark?'"FFFFFF"':'"4A4A4A"'},
            "borderRadius": 5,
            "city": "${that.cid}",
            "key": "ddea3b54e02846d29179522aa8d9086a"
          }
        }`;
          var js2 = document.createElement("script");
          js2.src =
            "https://widget.heweather.net/standard/static/js/he-standard-common.js?v=1.1";
          box.innerHTML = '<div id="he-plugin-standard"></div>';
          box.appendChild(js2);
          box.appendChild(js1);
        }
      }, 100);
    },
    // 获取当前城市ID
    getCityId(position) {
      var that = this;
      var url = `https://geoapi.heweather.net/v2/city/lookup?location=${position}&mode=fuzzy&key=${
        that.hefenKey
      }`;
      var xhr = new XMLHttpRequest();
      xhr.onreadystatechange = function() {
        if (
          xhr.readyState === 4 &&
          (xhr.status === 200 || xhr.status === 304)
        ) {
          var res = JSON.parse(xhr.responseText);
          // console.log(res);
          that.cid = "CN" + res.location[0].id;
          that.getCityWeather(that.cid);
        }
      };
      xhr.open("GET", url, true);
      xhr.send();
    },
    // 获取当前城市天气
    getCityWeather(cid) {
      var that = this;
      var url = `https://widget-api.heweather.net/s6/plugin/view?key=9a919ebc52a04181b258260b65cae49e&location=${cid}&lang=zh`;
      var xhr = new XMLHttpRequest();
      xhr.onreadystatechange = function() {
        if (
          xhr.readyState === 4 &&
          (xhr.status === 200 || xhr.status === 304)
        ) {
          var res = JSON.parse(xhr.responseText);
          // console.log(res);
          var time = new Date().getHours();
          var icon_d_n;
          if (time >= 6 && time <= 18) {
            icon_d_n = "d";
          } else {
            icon_d_n = "n";
          }
          that.imgSrc =
            "https://cdn.heweather.com/img/plugin/190516/icon/c/" +
            res.now.cond_code +
            icon_d_n +
            ".png";
          that.temperature = res.now.tmp;
        }
      };
      xhr.open("GET", url, true);
      xhr.send();
    }
  }
};
</script>

