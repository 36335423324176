<template>
    <div :class="['notice-view',swiperShow?'':'hide']">
        <div class="notice"></div>
        <div class="swiper-container" v-if="swiperShow">
            <div class="swiper-wrapper">
                <div class="swiper-slide" v-for="item in noticeData" @click="toNotice(item.id)">
                    <div class="notice-title" @click.stop="toNotice(item.id)">{{item.title}}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Swiper from "swiper";
import "swiper/css/swiper.css";

export default {
  name: "Notice",
  props: {
    noticeData: Array,
    toNotice: Function
  },
  data() {
    return {
      swiperShow:true,
    };
  },
  mounted() {
    var mySwiper = new Swiper(".swiper-container", {
      loop: false,
      disableOnInteraction : false,
      preventClicksPropagation:true,
      preventClicks:false,
      autoplay: {
        delay: 3000
      },
      direction: "vertical"
    });
  },
  methods:{
      closeNotice(){
        this.swiperShow = false;
      }
  },
};
</script>





