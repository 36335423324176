<template>
    <div class="detail-view animaton" :style="'bottom:'+ slideBottom + 'px;height:' + mainHeight + 'px;'">
        <div class="bg" v-if="modelViewShow"></div>

        <div class="return-top" v-if="modelViewShow&&moreShow" @touchstart="returnDefaultSlide"
            @click="returnDefaultSlide"></div>
        <div class="main" id="slideView" @touchstart.capture="slideStart" @touchmove.capture="slideMove"
            @touchend.capture="slideEnd" @mousedown.capture="slideStart" @mousemove.capture="slideMove"
            @mouseup.capture="slideEnd">
            <img class="bg-img" src="https://guchengwan.oss-cn-beijing.aliyuncs.com/images/a7mt16jm4g80000.png" alt="">
            <div class="exit-line" v-if="isLineMarker" @click="returnLine">返回主路线</div>
                <div v-if="!modelViewShow" class="flex ac jc change"  @click="Detail(4, 1)" >
                    <div class="marker-top"></div>
                </div>
                <div class="flex ac jc" v-else>
                    <div class="slide-up-btn"></div>
                </div>
            <div class="marker-info-view">
                <!-- <div class="slide-up-btn"></div> -->
                <div v-if="isLineMarker" class="line-marker-index">{{this.$parent.currentLineMarkersIndex+1}}</div>
                <div class="info-view">
                    <img class="marker-img" :src="markerData.img">
                    <div class="flex ac jb">
                        <p class="title only onlys">{{markerData.name}}</p>
                        <p v-if="markerData.label" class="labels flex ac jc">{{markerData.label}}</p>
                    </div>
                    <span v-if="this.$parent.currentPosition" class="distance">距您{{dis<1000?dis.toFixed(0) + 'm':(dis/1000).toFixed(2)+'km'}}</span>
                    <div class="flex ac" v-if="markerData.stars">
                        <div v-for="iten in Number(markerData.stars)" class="starts flex ac jc"></div>
                        <div v-for="iten in (5 - Number(markerData.stars))" class="startsd flex ac jc"></div>
                    </div>
                    <div :class="['audio-control', !this.$parent.currentPosition?'mt20':'']" v-if="markerData.audio_duration">
                        <span :class="['audio-btn',playing?'pause':'play']" @click="audioPlayToogle"></span>
                        <mt-range :value="playDur" :disabled="true" :min="0" :max="dur" :bar-height="2"></mt-range>
                        <span class="audio-btn gif"></span>
                        {{dur<60?'00:'+ (dur<10?"0" + dur:dur):(Math.floor(dur/60).toFixed(0)<10?'0' + Math.floor(dur/60).toFixed(0):Math.floor(dur/60).toFixed(0)) + ':' + (dur%60<10?'0'+ dur%60:dur%60)}}
                    </div>
                </div>
            </div>
            <div class="boxsf">
                <div class="show-height">
                    <div class="marker-detail-view more" v-if="markerData.info">
                        <div class="contant-view more">
                            <div class="title">简介</div>
                            <div class="info contant-info">
                                <div class="flex ac">开放时间：{{markerData.open}}</div>
                                <div class="flex ac">联系电话：{{markerData.tel}}</div>
                                <div v-html="markerData.info"></div>
                            </div>
                        </div>
                    </div>
                    <div class="marker-detail-view more" v-if="ticketList.length > 0">
                        <div class="contant-view more">
                            <div class="title">{{markerData.type == 1 ? '门票' : markerData.type == 2 ? '推荐菜品' : markerData.type == 3 ? '房型推荐' : '推荐去处' }}</div>
                            <div class="piaof" v-if="markerData.type == 1 || markerData.type == 3">
                                <section class="piao" v-for="item in ticketList">
                                    <div class="flex ac jb distent">
                                        <section class="titles only">{{item.UUtitle}}</section>
                                        <section class="dec flex ac">
                                            <section class="local"></section>
                                            <section class="only">{{item.title}}距您{{dis<1000?dis.toFixed(0) + 'm':(dis/1000).toFixed(2)+'km'}}</section>
                                        </section>
                                    </div>

                                    <section class="flex ac jb">
                                        <section class="prices">￥{{item.retail_price}}</section>
                                        <section class="yuding flex ac jc">预订</section>
                                    </section>
                                </section>
                            </div>
                            <section v-else class="piaos">
                                <div class="piaotab" v-for="item in ticketList" @click="openPage({url: '/pages/find/detail/index?title=' + item.pname + '&id=' + item.id + '&type=hot'})">
                                    <img :src="item.images" class="imgs" alt="">
                                    <div class="piaos-dec">
                                        <div class="only">{{item.pname}}</div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="markerData" :class="['detail-bottom flex ac jb',isIphoneX ? 'active' : '']">
                <div class="tabs flex ac jc" @click="openPage({url: '/pages/navigation/navigation?name='+ markerData.name + '&lat='+ markerData.position[1] + '&lng='+ markerData.position[0]})">
                    <div class="go flex ac jc"></div>去这里
                </div>
                <a :href="markerData.book_url" class="tabs flex ac jc" v-if="markerData.book_status != 0" >
                    <div class="yu flex ac jc"></div>预订
                </a>
                <div class="tabs flex ac jc" @click="openPage({url: '/pages/my/comment/index?id=' + markerData.id  + '&type=hot'})">
                    <div class="ping flex ac jc"></div>{{markerData.comments}}
                </div>
                <div class="tabs flex ac jc" @click="click_zan">
                    <div :class="['like flex ac jc',markerData.is_give == 1 ? 'actived' : '']"></div>{{markerData.thumb}}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Toast, Popup, Indicator } from "mint-ui";
var util = require("@/utils/util");
export default {
  name: "detailView",
  components: { Indicator },
  props: {
    isLineMarker: {
      type: Boolean,
      default: false
    },
    markerData: {
      type: Object,
      default: null
    },
    isAuth: Boolean
  },
  data() {
    return {
      langData: this.$parent.langData,
      sid: this.$parent.sid,
      is_auth: this.$parent.is_auth,
      slideBottom: 0,
      currentBottom: 0,
      mainHeight: 0,
      viewHeight: 0,
      slideShow: false,
      slideStated: false,
      isAanimation: false,
      slideStartY: 0,
      slideMovedY: 0,
      slideEndY: 0,
      slideStartTime: 0,
      slideEndTime: 0,
      speed: 0,
      moreShow: false,
      modelViewShow: false,
      dis: 0,
      playing: false,
      audioLoading: false,
      currentAudio: this.$parent.currentAudio,
      currentTargetAudio: this.$parent.currentTargetAudio,
      dur: this.markerData.audio_duration,
      playDur: 0,
      curTime: null,
      punch_open: false,
      punch_success: false,
      punch_success_show: false,
      ticketList:[],
      isIphoneX:false,
      isScroll: false, // 底部是否跟着运动
    };
  },
  watch: {
    playDur: function(newVal, oldVal) {
      var that = this;
      if (Math.abs(newVal - oldVal) > 9) {
        if (that.$parent.currentAudio.src == that.markerData.audioSrc) {
          that.$parent.currentAudio.currentTime = newVal;
        }
      }
    }
  },
  created() {

  },
  mounted() {
    var that = this;
    that.getTicket();
    that.recode(); // 记录点击
    var u = navigator.userAgent;
    var isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
    if (isIOS) {
        this.isIphoneX = true
    }
  },
  updated() {
    var that = this;
    if (that.$parent.currentPosition) {
      that.dis = AMap.GeometryUtil.distance(
        [that.markerData.position[0], that.markerData.position[1]],
        that.$parent.currentPosition
      );
    }
  },
  methods: {
    click_zan() {
        let that = this;
        if (that.markerData.is_give == 1) {
            var is_give = 1
        } else {
            var is_give = 2
        }
        util.requests("post", {
            url: "map_data/pointThumb",
            data:{point_id:that.markerData.id,is_give:is_give}
            }) .then(res => {
                if (res.code) {
                    if (that.markerData.is_give == 1) {
                        that.markerData.is_give = 0;
                        that.markerData.thumb = that.markerData.thumb - 1;
                        Toast({
                            message: "取消点赞",
                        });
                    } else{
                        that.markerData.is_give = 1;
                        that.markerData.thumb = that.markerData.thumb + 1;
                        Toast({
                            message: "点赞成功",
                        });
                    }
                }
            })
    },
    recode() {
        let that = this;
        util.requests("post", {
            url: "map_data/pointReadingAdd",
            data:{point_id:that.markerData.id,}
            }) .then(res => {
                
            })
    },
    getTicket() {
        let that = this;
        if (that.markerData.type == 1 || that.markerData.type == 3) {
            var url = 'Ticket/ticketPoint';
        } else {
            var url = 'map_data/hotPointList';
        }
        util.requests("post", {
            url: url,
            data:{icons_id:that.$parent.mapData[that.markerData.arrIndex].id,point_id:that.markerData.id,}
            }) .then(res => {
            if (res.code) {
                if (that.markerData.type == 1 || that.markerData.type == 3) {
                    that.ticketList = res.data;
                } else {
                    that.ticketList = res.data.data;
                }
                setTimeout(() => {
                    that.viewHeight = document.querySelector("html").clientHeight - 70;
                    var mainHeight = document.querySelector("#slideView");
                    that.mainHeight = mainHeight.clientHeight;
                    var slideBottom = document.querySelector("#slideView .marker-detail-view");
                    var boxHeight = document.querySelector("#slideView .boxsf").clientHeight;
                    if (slideBottom) {
                        if (that.slideBottom) {
                            that.slideBottom = -slideBottom.clientHeight + 40;
                        } else {
                            if (boxHeight <= '140') {
                                that.slideBottom = 0
                            } else {
                                that.slideBottom = -boxHeight + 150;
                            }
                        }
                    } else {
                        that.slideBottom = 0;
                    }

                    // 获取当前播放音频的时间
                    that.currentTargetAudio = new Audio();
                    that.currentTargetAudio.src = that.markerData.audioSrc;

                    // 判断当前点击是否为当前播放音频
                    // console.log(that.currentAudio);
                    if (that.currentAudio) {
                    if (that.currentTargetAudio.src != that.currentAudio.src) {
                        that.playing = false;
                        console.log("不相等");
                    } else {
                        console.log("相等");
                        if (that.currentAudio.paused) {
                        console.log("暂停");
                        that.curTime = setInterval(() => {
                            that.playDur = that.$parent.currentAudio.currentTime;
                        }, 200);
                        that.playing = false;
                        } else {
                        console.log("正在播放");
                        that.curTime = setInterval(() => {
                            if (that.$parent.currentAudio) {
                            that.playDur = that.$parent.currentAudio.currentTime;
                            } else {
                            clearInterval(that.curTime);
                            }
                        }, 200);
                        that.playing = true;
                        }
                    }
                    }
                    setTimeout(() => {
                        document.querySelector(".detail-view").className = "detail-view";
                    }, 300);
                }, 100);
            }
        });
    },
    audioBack() {
      var that = this;
      if (that.$parent.currentAudio.src == that.markerData.audioSrc) {
        if (that.playDur < 10) {
          that.playDur = 0;
        } else {
          that.playDur -= 0;
        }
      }
    },
    audioForward() {
      var that = this;
      if (that.$parent.currentAudio.src == that.markerData.audioSrc) {
        if (that.playDur + 10 > that.dur) {
          that.playDur = that.dur;
        } else {
          that.playDur += 0;
        }
      }
    },
    audioPlay() {
      var that = this;
      if (that.$parent.currentAudio.src == that.currentTargetAudio.src) {
        that.$parent.currentAudio.play();
        that.curTime = setInterval(() => {
          that.playDur = that.$parent.currentAudio.currentTime;
        }, 200);
        that.playing = true;
        that.$parent.currentAudio.addEventListener("ended", function() {
          clearInterval(that.curTime);
          that.playing = false;
          that.$parent.currentAudio.currentTime = 0;
          that.playDur = 0;
          that.$parent.currentAudio = null;
        });
      } else {
        that.$parent.currentAudio.src = that.currentAudio.src;
        that.audioLoading = true;
        that.$parent.currentAudio.load();
        that.$parent.currentAudio.addEventListener("canplay", function() {
          that.$parent.currentAudio.play();
          that.curTime = setInterval(() => {
            that.playDur = that.$parent.currentAudio.currentTime;
          }, 500);
          that.playing = true;
          that.audioLoading = false;
        });
        that.$parent.currentAudio.addEventListener("ended", function() {
          clearInterval(that.curTime);
          that.playing = false;
          that.$parent.currentAudio.currentTime = 0;
          that.playDur = 0;
          that.$parent.currentAudio = null;
        });
      }
    },
    audioPlayToogle() {
      var that = this;
      if (that.$parent.currentAudio) {
        if (that.$parent.currentAudio.src == that.markerData.audioSrc) {
          if (that.$parent.currentAudio.paused) {
            that.audioPlay();
          } else {
            that.$parent.currentAudio.pause();
            that.playing = false;
          }
        } else {
          that.$parent.currentAudio.src = that.markerData.audioSrc;
          that.audioPlay();
        }
      } else {
        that.$parent.currentAudio = new Audio();
        that.$parent.currentAudio.src = that.markerData.audioSrc;
        that.audioPlay();
      }
    },

    likeEvent() {
      let that = this;
      if (that.markerData.isLike) {
        that.markerData.like--;
      } else {
        that.markerData.like++;
      }
      that.markerData.isLike = !that.markerData.isLike;
      util
        .requests("post", {
          url: "Like/changeLike",
          data: { id: that.markerData.id,type:'point' }
        })
        .then(res => {
        });
    },

    returnLine() {
      var that = this.$parent;
      that.isLineMarker = false;
      that.prevTargetMarker.setContent(that.prevTargetMarker.getExtData().icon);
      that.prevTargetMarker.setOffset(new AMap.Pixel(-13, -13));
      that.prevTargetMarker.setLabel({ content: null, direction: "bottom" });
      that.detailViewShow = false;
    },

    slideStart(e) {
      var that = this;
      that.slideStated = true;
      that.isAanimation = true;
      var slideStartY = e.clientY || e.changedTouches[0].clientY;
      that.slideStartY = slideStartY;
      that.slideMovedY = 0;
      that.currentBottom = that.slideBottom;
      that.slideStartTime = new Date().getTime();
      var mainHeight = document.querySelector("#slideView");
      that.mainHeight = mainHeight.clientHeight;
    },

    slideMove(e) {
      var that = this;
      if (!document.querySelector("#slideView .marker-detail-view")) {
        return;
      }
        if (
        !e.target.className ||
        e.target.className == "contant-view more" ||
        e.target.className == "title" ||
        e.target.className == "piaos" ||
        e.target.className == "piaotab" ||
        e.target.className == "imgs" ||
        e.target.className == "piaos-dec" ||
        e.target.className == "only" ||
        e.target.className == "show-height" ||
        e.target.className == "flex ac" ||
        e.target.className == "piao" ||
        e.target.className == "title only" ||
        e.target.className == "dec flex ac" ||
        e.target.className == "local" ||
        e.target.className == "show-height" ||
        e.target.className == "titles only" ||
        e.target.className == "show-height" ||
        e.target.className == "show-height" ||
        e.target.className == "show-height" ||
        e.target.className == "piaof"
      ) {
        that.isScroll = true;
      } else {
        that.isScroll = false;
      }
      if (that.isScroll) {
        return;
      }
      var minBottom = document.querySelector("#slideView .marker-detail-view").clientHeight;
      var boxsf = document.querySelector("#slideView .boxsf").clientHeight;
      if (that.slideStated) {
        var slideMoveY = e.clientY || e.changedTouches[0].clientY;
        that.slideMovedY = that.slideStartY - slideMoveY;
        that.slideBottom = that.currentBottom + that.slideMovedY;
        console.log(minBottom,that.slideMovedY,boxsf)
        if (that.slideBottom > 20) {
          that.slideBottom = 20;
        } else if (that.slideBottom < -minBottom + 40) {
          that.slideBottom = -minBottom + 40;
        }
        if (
          that.mainHeight > that.viewHeight &&
          document.querySelector(".detail-view").offsetTop < 50
        ) {
          that.modelViewShow = true;
        } else {
          that.modelViewShow = false;
        }
      }
    },

    slideEnd(e) {
      var that = this;
      that.slideStated = false;
        if (that.isScroll) {
            that.isScroll = false;
            return;
        }
      var slideNext = that.slideMovedY;
      that.slideEndTime = new Date().getTime();
      var timeSub = that.slideEndTime - that.slideStartTime;
      if (timeSub > 0 && slideNext != 0) {
        var minBottom = document.querySelector("#slideView .marker-detail-view")
          .clientHeight;
         var boxHeight = document.querySelector("#slideView .boxsf").clientHeight;

        that.speed = Math.abs(Math.abs(slideNext) / timeSub) * 16;
        var f = 0;
        var timer = true;
        interval();
        function interval() {
          timer && requestAnimationFrame(interval);
          f = Math.min(Math.abs(that.speed) / 32, 0.5);
          that.speed -= f;
          if (document.querySelector(".detail-view")) {
            if (
              that.mainHeight > that.viewHeight &&
              document.querySelector(".detail-view").offsetTop < 10
            ) {
              that.modelViewShow = true;
            } else {
              that.modelViewShow = false;
            }
          }

          if (slideNext >= 0) {
            that.slideBottom += that.speed;
          } else {
            that.slideBottom -= that.speed;
          }
          if (that.speed <= 0.2 || that.slideStated) {
            timer = false;
            that.speed = 0;
            that.isAanimation = false;
          }
          if (that.slideBottom > -3) {
            that.slideBottom = 0;
          } else if (that.slideBottom < -boxHeight + 40) {
            that.slideBottom = -boxHeight + 0;
          }
          if (that.slideBottom == 0) {
              that.modelViewShow = true;
              that.moreShow = true
          }
        }
      } else {
        that.isAanimation = false;
      }
    },

    lookMore() {
      var that = this;
      that.moreShow = true;
      setTimeout(() => {
        var mainHeight = document.querySelector("#slideView");
        that.mainHeight = mainHeight.clientHeight;
        if (that.mainHeight > document.querySelector("html").clientHeight) {
          var slideBottom = document.querySelector(
            "#slideView .marker-detail-view"
          );
          that.slideBottom = -slideBottom.clientHeight + 500;
          that.modelViewShow = true;
        }
      }, 100);
    },
    Detail(e, f) {
      this.modelViewShow = true;
      this.moreShow = true;
      this.slideBottom = 0;
    },
    returnDefaultSlide() {
      var that = this;
      that.modelViewShow = false;
      that.speed = 0;
      var slideBottom = document.querySelector(
        "#slideView .marker-detail-view"
      );
        var aa = document.querySelector(
        "#slideView .boxsf"
      );
      console.log(slideBottom.clientHeight,aa.clientHeight)
      if (slideBottom.clientHeight > aa.clientHeight) {
        that.slideBottom = -aa.clientHeight + 150;
      } else {
        that.slideBottom = slideBottom.clientHeight - aa.clientHeight;
      }
      setTimeout(() => {
        document.querySelector(".detail-view").className = "detail-view";
      }, 300);
    },
    openPage: util.openPage
  }
};
</script>